import { COLOR_WHITE, COLOR_TEXT, COLOR_DARK, COLOR_DARKGRAY, COLOR_ORANGE_PRIMARY, COLOR_BLACK, COLOR_RED } from './constants/color';

import { createTheme } from '@mui/material/styles';
const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;
const theme = createTheme({
    breakpoints,
    palette: {
        primary: {
            main: '#F7941D',
        },
        secondary: {
            main: '#386BF6',
        },
        info: {
            main: '#838383',
        },
    },

    typography: {
        h1: {
            fontFamily: 'Poppins',
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 700,
            textAlign: 'left',
            color: COLOR_DARK,
            paddingBottom: '10px',
        },
        Paragraph: {
            color: COLOR_TEXT,
            fontSize: '15.3px',
            fontWeight: 400,
            fontFamily: 'Poppins',
        },

        subtitle1: {
            //width: '544px',
            fontFamily: 'Poppins',
            fontSize: '24px',
            lineHeight: '150%',
            fontStyle: 'normal',
            fontWeight: 700,
            paddingTop: '0px',
            textAlign: 'left',
            color: COLOR_DARK,
        },
        h6: {
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '18.87px',
            padding: '5px',
            textAlign: 'left',
            width: '279px',
            color: COLOR_DARK,
        },
        h4: {
            fontFamily: 'Poppins',
            fontSize: '30.769px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '125%',
            color: COLOR_DARK,
        },
        subtitle2: {
            fontFamily: 'Poppins',
            marginBottom: '5px',
            paddingBottom: '10px',

            lineHeight: '20px',
            fontSize: '14px',
            // fontStyle: 'normal',
            fontWeight: '300px',

            // textAlign: 'left',
            // color: COLOR_DARK,
        },
        // subtitle3:{
        //     fontFamily: 'Poppins',

        //     fontSize: '14px',

        // },
        body1: {
            fontFamily: 'Poppins',
            fontSize: '16px',
        },

        h5: {
            color: COLOR_DARKGRAY,
            fontFamily: 'Poppins',
            fontSize: '60px',
            fontStyle: 'normal',
            fontWeight: 'bolder',
            lineHeight: '65px',
        },

        checkbox: {
            color: '#000',

            fontFamily: 'Poppins',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '150%',
        },
        h2: {
            fontFamily: 'Poppins',
            fontWeight: '600',
            fontSize: '20.03px',
            lineHeight: '30.05px',
            letterSpace: '0.17 px',
            textalign: 'center',
            color: COLOR_TEXT,
        },

        TextField: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        h3: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 400,
            fontStyle: 'normal',
        },
    },

    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: COLOR_ORANGE_PRIMARY, 
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    scrollbarColor: "#E0E0E0 #FFF",
                },
            },
        },

        MuiToolbar: {
            styleOverrides: {
                root: {
                    padding: '0px 0px',
                    backgroundColor: '#FFF',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    '&.MuiTablePagination-toolbar': {
                        padding: '0px',
                        backgroundColor: 'transparent'
                    }
                },
                gutters: {
                    height: 'fit-content',
                    padding: '0px 0px',
                },
                regular: {
                    padding: '0px 0px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.MuiAppBar-root': {
                        color: '#838383',
                        fontFamily: 'Poppins',
                        fontSize: '18px',
                        fontWeight: 600,
                        backgroundColor: 'transparent'
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '1px 4px 4px 4px',

                    '& .Mui-checked': {
                        color: '#386BF6',
                    },

                    '& .MuiSvgIcon-root': {
                        height: '14px',
                        width: '14px',
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                barColorPrimary: {
                    backgroundColor: '#f7941d',
                }

            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiMenuItem-root': {
                        backgroundColor: '#FFF',
                        fontFamily: 'Poppins',
                        lineHeight: 1.5,
                        fontSize: '14px'
                    },
                    '&.MuiMenuItem-root.Mui-selected': {
                        backgroundColor: '#F7941D',
                        color: '#000',
                        fontFamily: 'Poppins',
                        // fontSize: '14px',
                    },
                    '&.MuiMenuItem-root.Mui-selected:hover': {
                        backgroundColor: '#F7941D',
                        color: '#ECECEC',
                        fontFamily: 'Poppins',
                        // fontSize: '14px',
                    },
                    '&.MuiTab-root': {
                        fontFamily: 'Poppins',
                        textAlign: 'center',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        color: COLOR_TEXT,
                        gap: '8px',
                        lineHeight: 'normal',
                        letterSpacing: '0.167px',
                        textTransform: 'none',
                        animation: 'none',
                        display: 'flex',
                        justifyContent: 'center !important',
                        padding: '8px 16px',
                        '@media(max-width: 1200px)': {
                            fontSize: '16px',
                        },
                    },
                    '&.MuiTab-root:hover': {
                        backgroundColor: '#F7941D',
                        color: '#838383',
                    },
                    '&.MuiTab-root.Mui-selected': {
                        color: '#FFF',
                        backgroundColor: '#F7941D',
                    },
                    '&.MuiPickersDay-root': {
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                    '&.MuiPickersDay-root:hover': {
                        color: '#F7941D',
                        backgroundColor: 'transparent',
                    },
                    '&.MuiPickersDay-root:active': {
                        color: '#F7941D',
                        backgroundColor: 'transparent',
                    },
                    '&.MuiPickersDay-root.Mui-selected': {
                        color: '#FFF',
                        backgroundColor: '#F7941D',
                    },
                    '&.MuiPickersDay-root.Mui-selected:hover': {
                        color: '#FFF',
                        backgroundColor: '#F7941D',
                    },
                    '&.MuiPickersDay-root.Mui-selected:focus': {
                        color: '#FFF',
                        backgroundColor: '#F7941D',
                    },
                    '&.MuiButton-root': {
                        // fontSize: '10px'
                    },
                    '&.MuiButton-root:hover': {
                        boxShadow: '0 4x 4px rgba(0, 0, 0, 0.4)',
                    },
                },
            },
        },
        //for mui date picker component
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    color: '#838383',
                    backgroundColor: 'transparent',
                    border: 0,
                    outline: 0,
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.75,
                    letterSpacing: '0.00938em',
                    margin: '8px 0',
                    height: '36px',
                    width: '72px',
                    borderRadius: '18px',
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#F7941D',
                        backgroundColor: 'transparent',
                    },
                    '&:active': {
                        color: '#F7941D',
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-selected': {
                        color: '#FFF',
                        backgroundColor: '#F7941D',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    '&.Mui-selected:hover': {
                        color: '#FFF',
                        backgroundColor: '#F7941D',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    '&.Mui-selected:active': {
                        color: '#FFF',
                        backgroundColor: '#F7941D',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    '&.Mui-selected:focus': {
                        color: '#FFF',
                        backgroundColor: '#F7941D',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F7941D',
                    padding: '16px',
                    display: 'flex',
                    maxHeight: '46px',
                    minHeight: '40px',
                    margin: '0px 0px',
                    fontFamily: 'Poppins',
                },
                labelContainer: {
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    color: '#ECECEC',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    color: '#838383',
                    '&.MuiDayCalendar-weekDayLabel': {
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        fontSize: '14px',
                    },
                },
            },
        },
        //Theme for  mui date picker completed

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    color: '#838383',
                    fontWeight: 400,
                    '&.Mui-focused': {
                        color: '#838383',
                    },
                    '&.Mui-error': {
                        color: '#838383',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-input': {
                        fontFamily: 'Poppins',
                        color: '#838383',
                        width: '100%',
                        height: 'fit-content',
                    },
                    '& .MuiInputBase-input': {
                        fontFamily: 'Poppins',
                        color: '#838383',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #838383',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        // border: `1px solid #838383`,
                        color: '#838383',
                    },
                    notchedOutline: {
                        border: '1px solid #838383',
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-root': {
                        color: COLOR_DARK,
                        fontSize:'1rem',
                        fontWeight:'400',
                        lineHeight: '140%',
                        
                    },
                    '&.MuiInputLabel-root.Mui-focused': {
                        color: '#838383',
                    },
                },
                asterisk: {
                    color: COLOR_RED,
                    '&$error': {
                        color: COLOR_RED
                    },
                }
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #838383',
                    },
                },
                select: {
                    fontFamily: 'Poppins',
                    color: '#838383',
                    fontSize: '14px',
                    fontWeight: 400,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    height: '20px',
                    margin: '0px',
                    fontFamily: 'Poppins',
                    color: '#EA4335',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    display: 'flex',
                    gap: '8px',
                    justifyContent: 'center',
                },
                indicator: {
                    height: '0px',
                    backgroundColor: '#F7941D',
                    transition: 'width 0.3s',
                    bottom: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    lineHeight: '125%',
                    textTransform: 'none',
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    '& .MuiButton-root': {
                        border: '1px solid #FFFFFF',
                        borderRadius: '6.825px',
                    },
                    '& .MuiButton-root:hover': {
                        border: '1px solid #FFFFFF',
                        transition: 'none',
                    },
                    '& .MuiButton-root:first-child': {
                        borderTopLeftRadius: '6.825px',
                        borderBottomLeftRadius: '6.825px',
                    },
                    '& .MuiButton-root:last-child': {
                        borderTopRightRadius: '6.825px',
                        borderBottomRightRadius: '6.825px',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.MuiInputBase-input ': {
                        // backgroundColor: '#FFF',
                        padding: '10px 10px 10px 10px'

                    },

                    '&.MuiInput-root:before': {
                        borderBottom: 'none',
                        webkitTransition: 'none',
                        transition: 'none',
                    },
                    '&.MuiInput-root:after': {
                        borderBottom: 'none',
                        transform: 'none',
                        webkitTransition: 'none',
                        transition: 'none',
                    },
                    '&.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: 'none',
                    },
                    '& .MuiInputBase-input': {
                        color: COLOR_TEXT,
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        letterSpacing: '125.914%',
                        padding: '10px 10px 10px 10px',
                        // backgroundColor: '#FFF'
                    },
                },
                input: {
                    '&.MuiInput-input': {
                        fontStyle: 'normal',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 400,
                        letterSpacing: '0px',
                        color: '#838383',
                        maxWidth: '100%',
                        // background: '#FFF',
                        // padding: '10px 0px 10px 10px',

                        // '@media(max-width: 720px)': {
                        //     width: '400px',
                        // },
                        // '@media(max-width: 470px)': {
                        //     width: '300px',
                        // },
                        // '@media(max-width: 380px)': {
                        //     width: '250px',
                        // },
                        // '@media(max-width: 330px)': {
                        //     width: '200px',
                        // },
                    },
                    '&.MuiOutlinedInput-input': {
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        color: '#838383',
                        letterSpacing: '0px',
                        padding: ' 10px',
                        // backgroundColor: '#FFF'
                    },
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    height: 'fit-content',
                    marginBottom: '0px',
                    paddingBottom: '0px',
                    gap: '0%',
                    '& .MuiGrid-item': {
                        // padding: '0px  16px 0px 16px'
                    }
                },
                item: {
                    height: 'fit-content',
                    marginBottom: '0px',
                    paddingBottom: '0px',
                    gap: '0%',
                },
            },
        },
        MuiStep: {
            styleOverrides: {
                root: {
                    '&.MuiStep-horizontal': {
                        padding: ' 0px 0px',
                        display: 'flex',
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: '#FFF',
                    fontFamily: 'Poppins !important',
                    boxShadow: '3px  #838383',
                    border: '1px solid #ECECEC',
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    '&.MuiStepper-horizontal': {
                        width: '250px',
                        '@media(max-width: 420px)': {
                            width: '200px',
                        },
                        '@media(max-width: 374px)': {
                            width: '120px',
                        },
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    // color: '#D1D5DB',
                    '&.MuiStepIcon-root': {
                        fill: '#ECECEC',
                        padding: '0px 0px',
                    },
                    '&.MuiStepIcon-root.Mui-completed': {
                        fill: '#386BF6!important',
                    },
                    '&.MuiStepIcon-root.Mui-active': {
                        fill: '#F7941D',
                    }
                },
                colorSecondary: {
                    color: '#386BF6'
                },
                colorPrimary: {
                    color: '#D1D5DB'
                }
            }
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    borderColor: '#D1D5DB',
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                columnHeaderTitle: {
                    fontFamily: 'Poppins !important',
                    fontSize: '14px !important',
                    fontWeight: 600,
                    color: COLOR_TEXT
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                        color: COLOR_BLACK
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiInput-input': {
                        padding: '0px !important'
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    fontFamily: 'Poppins'
                },
                displayedRows: {
                    fontFamily: 'Poppins'
                }
            }
        }
    },
});

//h1
theme.typography.h1['@media (max-width:900px)'] = {
    fontSize: '28px',
};
theme.typography.h1['@media  (max-width: 600px)'] = {
    fontSize: '24px',
};

//h5

theme.typography.h5['@media (max-width:1200px)'] = {
    fontSize: '48px',
    lineHeight: '48px',
};
theme.typography.h5['@media  (max-width: 900px)'] = {
    fontSize: '32px',
    lineHeight: '32px',
};
theme.typography.h5['@media (max-width: 600px)'] = {
    fontSize: '28px',
};

//h4
theme.typography.h4['@media (max-width:1200px)'] = {
    fontSize: '28px',
};
theme.typography.h4['@media  (max-width: 900px)'] = {
    fontSize: '24px',
};

export default theme;