import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';
import { RouterProvider, createHashRouter, useLocation } from 'react-router-dom';
import Routes from './routes';
import { useSelector, useDispatch } from 'react-redux';
import { showToast } from './Components/Dashboard/common/Toast/index';
import { getUserLogoutURL } from './services/api/endpoints';
import { logout } from './store/auth/LoginSlice';
import axios from 'axios';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { CircularProgress } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_OAUTH_CLIENT_ID } from './services/api/config';
import { fetchGenderData, fetchEthnicityData, fetchStatesData, fetchSchoolListData, fetchCollegeListData, fetchYearsInSchoolData } from './store/static/StaticDataSlice';
import { GOOGLE_ANALYTICS_TRACKING_ID } from './services/api/config.ts';
import TagManager from 'react-gtm-module';
import ReactGA from "react-ga4";
function App() {
  const dispatch = useDispatch();
  const staticDataFromRedux = useSelector((state) => state?.staticData);
  const loginDataFromRedux = useSelector((state) => state.login);
  const auth_token = loginDataFromRedux?.loginDetails?.data?.auth_token;
  const { error, isAuth, isLoading, loginDetails, signinResponse, token } = loginDataFromRedux;
  const userRole = loginDetails?.data?.user?.usersRoles?.[0]?.role?.normalizedName;

  //handle logout on multiple tabs
  useEffect(( ) => {
    const handleStorageChange = (event) => {
      if(event.key === 'logout') {
        dispatch(logout());
      }
    }
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage',handleStorageChange);
    }
  }, [dispatch]);

  useEffect(() => {
    let TRACKING_ID = GOOGLE_ANALYTICS_TRACKING_ID;
    ReactGA.initialize([
      {
        trackingId: TRACKING_ID
      }
    ]);
  }, []);

  useEffect(() => {

    if (staticDataFromRedux?.genderData === null) {
      const getGender = async () => {
        try {
          await dispatch(fetchGenderData());
        }
        catch (err) {
        }
      }
      getGender();
    }

    if (staticDataFromRedux?.ethnicitiesData === null) {
      const getEthnicities = async () => {
        try {
          await dispatch(fetchEthnicityData());
        }
        catch (err) {
        }
      }
      getEthnicities();
    }

    if (staticDataFromRedux?.statesData === null) {
      const getStates = async () => {
        try {
          await dispatch(fetchStatesData());
        } catch (err) {
        }
      }
      getStates();
    }

    if (staticDataFromRedux?.schoolsList === null) {
      const getSchoolsList = async () => {
        try {
          await dispatch(fetchSchoolListData());
        }
        catch (err) {
        }
      }
      getSchoolsList();
    }

    if (staticDataFromRedux?.collegesList === null) {
      const getCollegesList = async () => {
        try {
          await dispatch(fetchCollegeListData());
        }
        catch (err) {
        }
      }
      getCollegesList();
    }

    if (staticDataFromRedux?.yearsInSchoolIdData === null) {
      const getYearsInSchoolId = async () => {
        try {
          await dispatch(fetchYearsInSchoolData());
        }
        catch (err) {
        }
      }
      getYearsInSchoolId();
    }
  }, [dispatch])

  const isValidJSON = (json: any) => {
    try {
      JSON.parse(JSON.stringify(json));
      return true;
    } catch (error) {
      return false;
    }
  }
  useEffect(() => {
    function getTokenExpirationDate(token: string) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      if (decodedToken && decodedToken.exp) {
        const expDate = new Date(decodedToken.exp * 1000);
        return expDate;
      }
      return null;
    };

    function isTokenExpired(token: string) {
      const expirationDate = getTokenExpirationDate(token);
      if (!expirationDate) {
        return true;
      }
      return expirationDate <= new Date();
    };

    const checkTokenExpiration = async () => {
      // debugger;
      const persistRoot = localStorage.getItem('persist:root');
      if (persistRoot) {
        try {
          const persistRootJSON = JSON?.parse(persistRoot);
          if (isValidJSON(persistRoot)) {
            const loginDataJSON = JSON?.parse(persistRootJSON?.login);
            if (isValidJSON(loginDataJSON) && loginDataJSON?.isAuth) {
              const tokenDataJSON = JSON?.parse(loginDataJSON?.token);
              if (isValidJSON(tokenDataJSON)) {
                const token = tokenDataJSON?.payload?.data?.auth_token;
                if (token) {
                  if (isTokenExpired(token)) {
                    try {
                      const res = await axios.post(`${getUserLogoutURL}`, {}, {
                        headers: {
                          'Authorization': `Bearer ${auth_token}`,
                        },
                      })
                      if (res.data.code === 200) {
                        dispatch(logout())
                          .then(() => {
                            localStorage.clear();
                            sessionStorage.clear();
                            showToast("Your session has expired. Please log in again.", {

                              type: 'error'
                            });

                          })
                          .catch((error) => {
                            localStorage.clear();
                            sessionStorage.clear();
                            console.error('Logout error:', error);
                          });

                      }
                      else {
                        localStorage.clear();
                        sessionStorage.clear();

                        showToast(`Your session has expired. Please log in again.`, {




                          type: 'error'
                        });
                      }
                    }
                    catch (error) {
                      localStorage.clear();
                      sessionStorage.clear();
                      showToast(`Your session has expired. Please log in again.`, {
                        type: 'error'
                      });
                    }
                    

                  }
                }
              }
            }
          }
        }


        catch (error) {
        }

      }

    };

    checkTokenExpiration();
  }, []);



  const handleEnvironmentChange = () => {
    sessionStorage.clear();
  };

  useEffect(() => {
    window.addEventListener('environmentChange', handleEnvironmentChange);
    return () => {
      window.removeEventListener('environmentChange', handleEnvironmentChange);
    };
  }, []);

  const isProduction = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'qa';

  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        isVerboseMode={false}
        loadingComponent={<CircularProgress />}
        metaFileDirectory={'.'}
      >
        <Router>
          <Routes />
        </Router>
      </CacheBuster>
    </GoogleOAuthProvider>
  )
}
export default App;