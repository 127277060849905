import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import ExternalRoutes from './ExternalRoutes';
import DashboardRoutes from './DashboardRoutes';
import Header from '../Layout/Header/index.tsx';
import Footer from '../Layout/Footer/index.tsx';
import CopyRight from '../Layout/CopyRight/index.tsx';
import { CircularProgress } from '@mui/material';
import { LoadingFallback } from '../Components/LoadingFallback/LoadingFallback.tsx';
import { lazyWithPreload } from '../services/prefetch/prefetch.ts';

const Home = lazyWithPreload(() => import('./../pages/Home'));
const Dashboard = lazyWithPreload(() => import('../pages/Dashboard/index'));
const NoPageFound = lazyWithPreload(() => import('../pages/NotFoundPage/NotFoundPage.tsx'));


const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // alert(location.pathname)
    // const isPublicPath = /^\/schools\/[^/]+\/scholarships/.test(location.pathname);
    const isPublicPath = location.pathname.startsWith('/schools/');
    const isDashboardChildRoute = location.pathname.startsWith('/student') || location.pathname.startsWith('/counselor') || location.pathname.startsWith('/admin') || location.pathname.startsWith('/dashboard');
    const loginDataFromRedux = useSelector((state) => state.login);
    const { isAuth, loginDetails } = loginDataFromRedux;
    const userRole = loginDetails?.data?.user?.usersRoles?.[0]?.role?.normalizedName;
    const userRoleAvailable = ['STUDENT', 'COUNSELOR', 'ADMIN'];

    return (
        <>
            {isPublicPath || isDashboardChildRoute ? null : <Header />}
            <Routes>
                <Route path="/" element={
                    (!isAuth || !userRoleAvailable.includes(userRole)) ?
                        (
                            <React.Suspense fallback={<LoadingFallback />}>
                                <Home />
                            </React.Suspense>
                        )
                        :
                        <Navigate to={`/dashboard/${userRole.toLowerCase()}`} replace />
                } />
                <Route path="/*" element={<PublicRoutes />} />
                <Route path="/dashboard/*" element={
                    (isAuth && userRoleAvailable.includes(userRole)) ? (
                        <React.Suspense fallback={<LoadingFallback />}>
                            <DashboardRoutes />
                        </React.Suspense>

                    )
                        :
                        (
                            <Navigate to="/users/login" replace />
                        )
                }
                />
                <Route path="/schools/*" element={<ExternalRoutes />} />
                <Route path="*" element={<NoPageFound />} />
            </Routes>
            {isPublicPath || isDashboardChildRoute ? null : <Footer />}
            <CopyRight />
        </>
    )
};

export default App;


