import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { COLOR_LINKTEXT, COLOR_ORANGE_PRIMARY, COLOR_DARK, COLOR_WHITE, COLOR_SECONDARY, COLOR_TEXT } from '../../constants/color';
import InstagramIcon from '@mui/icons-material/Instagram';
import Linkedin from '../../assets/images/Footer/Linkedin.svg';
import Facebook from '../../assets/images/Footer/Facebook.svg';
import GreenBlurVector from '../../assets/images/Header/BlurredDrawer/Vector-blurred-green.svg';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from 'react-redux';


export default function SwipeableTemporaryDrawer() {
  const loginDataFromRedux = useSelector((state) => state.login);
  const { error, isAuth, isLoading, loginDetails, signinResponse, token } = loginDataFromRedux;
  const [state, setState] = useState<boolean>(false);
  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState(open);
      };

  const socialMediaLinks =
  {
    'instagram': 'https://www.instagram.com/myscholarbox/',
    'facebook': 'https://www.facebook.com/people/Scholar-Box/61551602767879/',
    'linkedIn': 'https://www.linkedin.com/company/scholar-box?trk=public_profile_experience-item_result-card_subtitl'
  }

  const upperLinks =
    !isAuth ?
      [
        { linkName: 'Home', target: '/' },
        { linkName: 'Sign Up', target: '/signup' },
        { linkName: 'Login', target: '/users/login' },
        { linkName: 'School Counselor', target: '/aboutCounselor' },
        { linkName: 'Learning Center', target: '/learningCenter/out' },
      ]
      :
      [
        { linkName: 'Dashboard', target: '/' },
        { linkName: 'School Counselor', target: '/aboutCounselor' },
        { linkName: 'Learning Center', target: '/learningCenter/out' },
      ];
  const lowerLinks =
    [
      { linkName: 'Frequently Asked Questions', target: '/faq' },
      { linkName: 'About Us', target: '/aboutUs' },
      { linkName: 'Contact Us', target: '/contact' },
    ];

  const list = () => (
    <Box
      sx={{
        width: 320,
        height: '100%',
        background: 'linear-gradient(135deg, #FFF 29.89%, #999 159.63%)',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box>
        <Box sx={{
          width: '100%',
          textAlign: 'right',
          margin: '20px 0',
          padding: '0 10px'
        }}>
          <Tooltip title="Close">
            <CancelIcon
              style={{
                color: COLOR_ORANGE_PRIMARY,
                cursor: 'pointer',
                width: '36px',
                height: '36px'
              }}
              onClick={toggleDrawer(false)} />
          </Tooltip>
        </Box>
        <List>
          {upperLinks.map((item, index) => (
            <ListItem key={item?.linkName} disablePadding>
              <Link to={item?.target} style={{ textDecoration: 'none' }}>
                <ListItemButton>
                  <ListItemText primary={item?.linkName} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box>
        <List>
          {lowerLinks.map((item, index) => (
            <ListItem key={item?.linkName} disablePadding>
              <Link to={item?.target} style={{ textDecoration: 'none' }}>
                <ListItemButton>
                  <ListItemText primary={item?.linkName} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Box display="flex" gap={3} justifyContent="center" alignItems="center" paddingTop={4} paddingBottom={4}>
          <Link to={socialMediaLinks?.linkedIn} target='_blank'>
            <img src={Linkedin} alt="Scholar Box LinkedIn Page" className="media" />
          </Link>

          <Link to={socialMediaLinks?.facebook} target='_blank'>
            <img src={Facebook} alt="Scholar Box Facebook Page" className="media" />
          </Link>

          <Link to={socialMediaLinks?.instagram} style={{ marginBottom: '6.42px' }} target='_blank'>
            {/* <img src={<InstagramIcon/>} alt="Scholar Box Instagram Page" className="media" /> */}
            <Box sx={{ width: '40px', height: '40.97px', borderRadius: '50%', border: '1px solid #B0B0B0', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <InstagramIcon sx={{ color: '#000' }} />
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <React.Fragment key={'right'}>
        <IconButton
          size="large"
          edge="end"
          aria-lable="menu"
          onClick={toggleDrawer(true)}>
          <MenuIcon
            sx={{
              color: COLOR_TEXT,
              '&:hover': {
                color: COLOR_ORANGE_PRIMARY
              }
            }}
          />
        </IconButton>
        <SwipeableDrawer
          anchor={'right'}
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={true}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </Box>
  );
}
