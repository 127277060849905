import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../Components/Dashboard/common/Toast';
import { makeStyles } from '@mui/styles';
import { Box, Paper, ThemeProvider, IconButton, createTheme, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR_LINKTEXT, COLOR_ORANGE_PRIMARY, COLOR_WHITE } from '../../constants/color';
import CloseIcon from '@mui/icons-material/Close';
import EastIcon from '@mui/icons-material/East';
import Linkedin from '../../assets/images/Footer/Linkedin.svg';
import Facebook from '../../assets/images/Footer/Facebook.svg';
import Twitter from '../../assets/images/Footer/Twitter.svg';

const useStyles = makeStyles((theme) => ({
    studentCounselorText: {
        '&:hover': {
            color: 'orange', // Set the desired hover color
        },
    },
}));


const CustomLink = styled(Link)(() => ({
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: '0px',
    lineHeight: '19.84px',
    fontStyle: 'normal',
    textDecoration: 'none',
    '-webit-transition': 'none',
    fill: '#FFF',
    color: '#000',
}));

const LinkItem = styled(Typography)(() => ({
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: '0px',
    lineHeight: '19.84px',
    fontStyle: 'normal',
    textDecoration: 'none',
    '-webit-transition': 'none',
    fill: '#FFF',
    color: '#000',
}));

const NavItem = styled(Typography)({
    marginBottom: '8px',
});

const MailLink = styled(Typography)({
    marginTop: '8px',
});

const NavItemDrawer = styled(Typography)({
    marginBottom: '28px',
});

const HeaderPopover = ({ onClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth_token = useSelector((state: any) => state.login.loginDetails.data?.auth_token);
    const loggedInUserData = useSelector((state: any) => state.login.loginDetails.data);
    const userRole = loggedInUserData?.user?.usersRoles?.[0]?.role?.title;
    const [userRoleRedirectPath, setUserRoleRedirectPath] = useState('');
    useEffect(() => {
        if (userRole === 'Student') {
            setUserRoleRedirectPath('dashboard/student');
        } else if (userRole === 'Admin') {
            setUserRoleRedirectPath('dashboard/admin');
        } else if (userRole === 'Counselor') {
            setUserRoleRedirectPath('dashboard/counselor');
        } else {
            setUserRoleRedirectPath('dashboard/student');
        }
    }, [userRole]);
    return (
        <>
            <Paper elevation={0} sx={{ pt: '48px' }}>
                <IconButton sx={{ position: 'absolute', top: '16px', right: '24px' }} onClick={onClose}>
                    <EastIcon />
                </IconButton>
                <Box p="0px 24px" sx={{ borderBottom: '2px  solid #e5e7eb' }}>
                    <NavItemDrawer>
                        <CustomLink to="/" onClick={onClose}>
                            Home
                        </CustomLink>
                    </NavItemDrawer>
                    <NavItemDrawer>
                        <CustomLink to="/signup/" onClick={onClose}>
                            Sign Up
                        </CustomLink>
                    </NavItemDrawer>

                    <NavItemDrawer>
                        {auth_token && auth_token !== 'undefined' ? (
                            <CustomLink to={`${userRoleRedirectPath}`} onClick={onClose}>
                                Dashboard
                            </CustomLink>
                        ) : (
                            <CustomLink to="/users/login/" onClick={onClose}>
                                Login
                            </CustomLink>
                        )}
                    </NavItemDrawer>
                    <NavItemDrawer>
                        <CustomLink to="/aboutCounselor/" onClick={onClose}>
                            School Counselor
                        </CustomLink>
                    </NavItemDrawer>
                    <NavItemDrawer>
                        <CustomLink to="/aboutCounselor/" onClick={onClose}>
                            Learning Center
                        </CustomLink>
                    </NavItemDrawer>
                    <NavItemDrawer>
                        <CustomLink to="/faq/" onClick={onClose}>
                            Frequently Asked Questions
                        </CustomLink>
                    </NavItemDrawer>
                    <NavItemDrawer>
                        <CustomLink to="/aboutUs/" onClick={onClose}>
                            About Us
                        </CustomLink>
                    </NavItemDrawer>
                    <NavItemDrawer>
                        <CustomLink to="/contact/" onClick={onClose}>
                            Contact Us
                        </CustomLink>
                    </NavItemDrawer>

                    <NavItemDrawer>
                        <LinkItem>Get In Touch</LinkItem>
                        <MailLink>
                            <a className="mail-link" href="mailto:support@scholarbox.org">
                                support@scholarbox.org
                            </a>
                        </MailLink>
                    </NavItemDrawer>
                </Box>

                <Box p="24px 24px 24px 24px">
                    <NavItemDrawer>
                        <LinkItem>Social</LinkItem>
                    </NavItemDrawer>
                    <Box display="flex" gap={1} justifyContent="space-between" alignItems="center" width="fit-content">
                        <Link to="/">
                            <img src={Linkedin} alt="socialmedia" className="media" />
                        </Link>

                        <Link to="/">
                            <img src={Facebook} alt="socialmedia" className="media" />
                        </Link>

                        <Link to="/">
                            <img src={Twitter} alt="socialmedia" className="media" />
                        </Link>
                    </Box>
                </Box>
            </Paper>

        </>
    );
};
export default HeaderPopover;
