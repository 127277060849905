import { Grid, Box, TextField, createTheme, ThemeProvider, InputAdornment, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import Linkedin from '../../assets/images/Footer/Linkedin.svg';
import Facebook from '../../assets/images/Footer/Facebook.svg';
import { styled } from '@mui/material/styles';
import { COLOR_ORANGE_PRIMARY, COLOR_TEXT, COLOR_BLACK } from '../../constants/color';
import ArrowSVG from '../../assets/images/Footer/Arrow-subscribe.svg';
import ComponentContainer from '../../Layout/Wrapper/componentContainer';
import { NavItem } from '../../Components/Account/CommonStyledComponents/componentHeaderText';
import { showToast } from '../../Components/Dashboard/common/Toast';
import LogoImage from '../../assets/images/Scholar-Box-Logo.png';
import axios from 'axios';
import { Formik, Form, Field, useFormik, FormikProvider, FormikConfig } from 'formik';
import * as Yup from 'yup';
import { handlePrefetchPage } from '../../utils/prefetchUtils';
import { PublicPageSmallText } from '../../Components/Typography/PublicPageSmallText';
import Header5Text from '../../Components/ui/H5';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const errorMessageStyle = {
    position: 'absolute',
    top: '38px',
    fontSize: '12px',
    color: '#EA4335',
    left: 0,
    width: 'fit-content',
    leadingTrim: 'both',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.5px',
};

const SubscribeSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email.'),
});


// const NavItem = styled(Typography)({ theme })({
//     marginBottom: '8px',
//     color: COLOR_TEXT,
//     fontFamily: 'Poppins',
//     fontSize: '18px',
//     fontStyle: 'normal',
//     fontWeight: 500,
//     lineHeight: '125%',
//     '&:hover': {
//         color: COLOR_ORANGE_PRIMARY,
//     },
//     [theme.breakpoints.down('sm')]: {
//         fontSize: '15px',
//     },
// });

const CustomTextField = styled(TextField)((props) => ({
    color: COLOR_TEXT,
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '1.019px',
    border: '1.28px solid #E7E8F2 !important',
    borderTopLeftRadius: '6px !important',
    borderBottomLeftRadius: '6px !important',
    width: '90% !important',
    height: '40px',
    // padding: '2px 0px 0px 10px!important',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
    '& .MuiInput-root': { fontFamily: 'poppins' },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
        // height:'45px',
    },

    '& .MuiInputBase-input.MuiOutlinedInput-input: focus': {
        // height:'45px',
        borderColor: 'black',
    },

    '@media (max-width: 360px)': {
        width: '80%',


    },

    '@media (min-width: 361px) and (max-width: 414px)': {
        width: '80%',


    },
    '@media (min-width: 415px) and (max-width: 768px)': {
        width: '80%',

    },

    '@media (min-width: 700px) ': {
        width: '80%',

    },
    '@media (min-width: 471px) ': {
        width: '80%',

    },

}));

const socialMediaLinks =
{
    'instagram': 'https://www.instagram.com/myscholarbox/',
    'facebook': 'https://www.facebook.com/people/Scholar-Box/61551602767879/',
    'linkedIn': 'https://www.linkedin.com/company/scholar-box?trk=public_profile_experience-item_result-card_subtitl'
}

const scrollToTop = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
};
const Footer = () => {
    const auth_token = useSelector((state: any) => state.login.loginDetails.data?.auth_token);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');

    let isSubscribe = false; // Initialize flag to track subscription operation status

    const formikConfig: FormikConfig<FormInputProps> = {
        initialValues: {
            email: '',
        },
        validationSchema: SubscribeSchema,
        onSubmit: handleSubmit,
    };

    const formik = useFormik(formikConfig);

    const handleChange = (fieldName: string, value: any) => {
        formik.setFieldValue(fieldName, value);
        formik.handleChange(fieldName);
    };

    const handleSubscribe = async (value) => {
        if (isSubscribe) {
            return;
        }
        const payload = {
            email: value,
        };
        try {
            isSubscribe = true; // Set the flag to indicate subscription operation is in progress

            // const requestOptions = {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(payload),
            // };

            // const res = await fetch(`${getUserSubscribeURL}?email=${payload.email}`, requestOptions);
            // const data = await res.json();

            // // Check if subscription was successful
            // if (data.success) {
            //     // Show success message in toast
            //     showToast(data.message[0], {
            //
            //
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //
            //         type: 'success',
            //     });
            // } else {
            //     // Check for specific error messages
            //     if (data.message.includes('Invalid email format')) {
            //         // Show error message for invalid email format in toast
            //         showToast('Please enter a valid email address.', {
            //
            //
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //
            //             type: 'error',
            //         });
            //     } else {
            //         // Show generic error message in toast for other error messages
            //         showToast(data.message[0], {
            //
            //
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //
            //             type: 'success',
            //         });
            //     }
            // }
        } catch (error) {
            console.error('Error:', error);
            showToast('An error occurred while subscribing.', {
                // Display a generic error message

                type: 'error',
            });
        } finally {
            isSubscribe = false;
        }
    };

    function handleSubmit(e) {
        // e.preventDefault();
        handleSubscribe(formik.values.email);
        formik.setTouched({
            email: true,
        });
        if (formik.values.email === '' || null) {
            showToast('Please enter an email address.', {
                // Display a generic error message

                type: 'error',
            });
        } else {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(formik.values.email)) {
                showToast('Please enter a valid email address.', {
                    type: 'error',
                });
            } else {
                window.open(
                    `https://scholar-box.us7.list-manage.com/subscribe/post?u=9a48757c09e4d75b99ce10cff&id=f9282825b3&f_id=001ad8e4f0&EMAIL=${encodeURIComponent(
                        formik.values.email
                    )}`,
                    '_blank'
                );
                formik.values.email = '';
                showToast('Subscription successful!', {
                    type: 'success',
                });
            }
        }
    }

    useEffect(() => {
        formik.setFieldValue('email', '');
    }, [window?.location?.pathname]);

    return (
        <ComponentContainer>
            <Box className="footer-main">
                <Box className="footer-main__compartments">
                    <Link to="/">
                        <img height="66px" src={LogoImage} alt="Scholar Box Logo" />
                    </Link>
                    <PublicPageSmallText text='Scholar Box is the platform designed to help both students and counselors find the perfect scholarships 
                                for college students. Scholar Box provides the resources you need to make the scholarship search simple and effective.
                                Start exploring and secure the funding you need today!' />
                </Box>
                <Box className="footer-main__compartments">
                    <Header5Text text='Quick Links' sx={{ color: COLOR_BLACK, pt: 1.5 }} />
                    <Box className="footer-main__links-div">
                        <Box className="footer-main__links-list">
                            <Link to="/faq" className="footer-main__link" onClick={scrollToTop} onMouseEnter={() => handlePrefetchPage('faqPage')}>
                                <NavItem> Frequently Asked Questions</NavItem>
                            </Link>
                            <Link to="/aboutUs" className="footer-main__link" onClick={scrollToTop} onMouseEnter={() => handlePrefetchPage('aboutUsPage')}>
                                <NavItem> About Us</NavItem>
                            </Link>
                            <Link to="/learningCenter/out" className="footer-main__link" onClick={scrollToTop} onMouseEnter={() => handlePrefetchPage('learningCenterOutPage')}>
                                <NavItem> Learning Center</NavItem>
                            </Link>
                            <Link to="/contact" className="footer-main__link" onClick={scrollToTop} onMouseEnter={() => handlePrefetchPage('contactUsPage')}>
                                <NavItem>Contact Us</NavItem>
                            </Link>
                        </Box>
                        <Box className="footer-main__links-list">
                            <Link to="/terms" onClick={scrollToTop} style={{ textDecoration: 'none' }} className="footer-main__link">
                                <NavItem> Terms & Conditions</NavItem>
                            </Link>
                            <Link to="/privacy-policy" onClick={scrollToTop} style={{ textDecoration: 'none' }} className="footer-main__link">
                                <NavItem> Privacy Policy</NavItem>
                            </Link>
                        </Box>
                    </Box>
                </Box>
                <Box className="footer-main__compartments">
                    <Box className="footer-main__subscribe-div">
                        <Header5Text text="Subscribe" sx={{ color: COLOR_BLACK, pt: 1.5 }} />
                        <Box sx={{ pt: 1.5, width: '100% ' }}>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box sx={{ display: 'flex', height: '40px'}}>
                                        <CustomTextField
                                            placeholder="Email Address"
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                            margin="none"
                                            fullWidth
                                            value={formik.values.email}
                                            onChange={(e) => handleChange('email', e.target.value)}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={
                                                <span style={errorMessageStyle}>
                                                    {formik.touched?.email && formik.errors.email}
                                                </span>
                                            }
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                        />
                                        <button className="footer-main__adornment-button" type="submit" onClick={() => handleSubscribe(inputValue)}>
                                            <ArrowForwardIcon />
                                        </button>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        </Box>
                        <Box className="text-div" sx={{ pt: 1.5, pb: 1.5 }}>
                            <PublicPageSmallText
                                text='ScholarBox: Revolutionizing scholarship searches. Discover scholarships, colleges, and
                                essay resources. Simplifying the path to academic success.'/>

                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end' , gap:'10px'}}>
                            <Link to={socialMediaLinks?.linkedIn} target='_blank'>
                                <img src={Linkedin} alt="Scholar Box LinkedIn Page" className="media" />
                            </Link>

                            <Link to={socialMediaLinks?.facebook} target='_blank'>
                                <img src={Facebook} alt="Scholar Box Facebook Page" className="media" />
                            </Link>

                            <Link to={socialMediaLinks?.instagram} style={{ marginBottom: '6.42px' }} target='_blank'>
                                {/* <img src={<InstagramIcon/>} alt="Scholar Box Instagram Page" className="media" /> */}
                                <Box sx={{ width: '40px', height: '40.97px', borderRadius: '50%', border: '1px solid #D0D0D0', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                    <InstagramIcon sx={{ color: '#000' }} />
                                </Box>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ComponentContainer>
    );
};
export default Footer;