import { Typography, styled } from '@mui/material';
import { COLOR_BLACK, COLOR_DARK, COLOR_ORANGE_PRIMARY, COLOR_SUBHEADING, COLOR_TEXT } from '../../../constants/color';

export const HeaderText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '2.5rem',
    lineHeight: '120%',
    fontWeight: '700',
    wordSpacing: '16%',
    color: COLOR_DARK,
    textAlign: 'center',
    // marginTop: '5%',
    marginBottom: '32px',
    letterSpacing: '0.05rem',
    [theme.breakpoints.down('md')]: {
        fontSize: '1.75rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
    },
}));

export const Header3Text = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '120%',
    color: COLOR_DARK,
    fontSize: '1rem',
    letterSpacing: '0.05rem',
    fontWeight: 600,
    margin: '8px 0px',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    },
}));

export const SubHeaderText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '1.125rem',
    lineHeight: '140%',
    fontWeight: 500,
    color: COLOR_SUBHEADING,
    textAlign: 'center',
    paddingLeft: '5px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
    },
    cursor: 'pointer',
}));

export const NavItem = styled(Typography)(({ theme }) => ({
    marginBottom: '8px',
    color: COLOR_BLACK,
    fontFamily: 'Poppins',
    fontSize: '0.825rem',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    lineHeight: '200%',
    '&:hover': {
        color: COLOR_ORANGE_PRIMARY,
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.825rem',
    },
}));
